// src/utils/DateUtils.jsx


/*
* Returns the date 9 months ago from the current date
*/
export function getDateXMonthsAgo(numberOfMonths = 6) {
    numberOfMonths = 9;
    var d = new Date();
    d.setMonth(d.getMonth() - numberOfMonths);
    return d;
}