import React from "react";
import ProductShowcase from "./ProductShowcase";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { lightenColor } from "../utils/ColorUtils";

export default function ShowcaseTable(products, categories) {

  const getMainCategories = categories.filter(category => !category.isSubcategory && category.visible);
  const getSubcategories = categories.filter(category => category.isSubcategory && category.visible);

  const sortByDisplayOrder = (a, b) => {
    if (a.displayOrder === null || a.displayOrder === undefined) return 1;
    if (b.displayOrder === null || b.displayOrder === undefined) return -1;
    return a.displayOrder - b.displayOrder;
  };

  const sortedCategories = getMainCategories.sort(sortByDisplayOrder);
  const sortedSubcategories = getSubcategories.sort(sortByDisplayOrder);

  return (
    <div className="grid-item">
      {sortedCategories
        .filter(category => category.visible)
        .map((category) => (
          <div key={category.ID} className="Product-Group">
            <h4 className="Category-Header row center anchor" style={{ backgroundColor: category.hexColor }} id={`${category.name}`}>{category.name}</h4>
            {sortedSubcategories
              .filter(subcategory => subcategory.parentCategoryId === category.ID)
              .map((subcategory) => (
                <div key={subcategory.ID} className="Product-Group">
                  <h4 className="Category-Header row center anchor" style={{ backgroundColor: lightenColor(category.hexColor, 30) || subcategory.hexColor }} id={`${subcategory.name}`}>{subcategory.name}</h4>
                  <Row className="row center">
                    {products.filter((products) => products.category_id === subcategory.ID).sort().reverse().map((products) =>
                    (
                      <Col sm={3} md={3} lg={3} className="">
                        <ProductShowcase
                          key={products.Product_id}
                          product={products}
                        ></ProductShowcase>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
            {products.filter((products) => products.category_id === category.ID).length > 0 && (
              <>
                {sortedSubcategories.some(subcategory => subcategory.parentCategoryId === category.ID) && (
                  <h4 className="Category-Header row center anchor" style={{ backgroundColor: lightenColor(category.hexColor, 30) }} id={`${category.name}-Classic`}>
                    {category.name} Classic
                  </h4>
                )}<Row className="row center">
                  {products.filter((products) => products.category_id === category.ID).sort().reverse().map((products) => (
                    <Col sm={3} md={3} lg={3} className="">
                      <ProductShowcase
                        key={products.Product_id}
                        product={products}
                      ></ProductShowcase>
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </div>
        ))
      }
    </div >
  );
}