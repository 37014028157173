import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import API from "../utils/API";
import { Button } from 'react-bootstrap';
import styles from './Navigation.css';
import Category from "../models/Category.jsx";
import { mapDataToCategories } from "../utils/DataUtils";
import { lightenColor } from "../utils/ColorUtils";


/***
    * This function checks if the category has new products or if any of its subcategories have new products
 ****/
function checkMainOrSubCategoryNewProducts(category, subcategories) {
    return category.hasNewProducts || subcategories.length > 0 && subcategories.some(subcategories => (subcategories.hasNewProducts));
}

export default function SideBarNavigation(
    { nonProductPage = true } = {}
) {
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState([]);
    const [prevPath, setPrevPath] = useState("");
    const api_url = API();
    const location = useLocation();

    useEffect(() => {
        const fetchCategories = async () => {
            const { data } = await axios.get(`${api_url}/api/db/categories`);
            const categories = mapDataToCategories(data);
            const tempCategories = categories.filter(category => !category.isSubcategory);
            const tempSubcategories = categories.filter(category => category.isSubcategory);
            setCategories(tempCategories);
            setSubcategories(tempSubcategories);
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        // Collapse all expanded categories when the route changes
        if (location.pathname !== prevPath) {
            setExpandedCategories([]);
            setPrevPath(location.pathname);
        }
    }, [location, prevPath]);

    const sortByDisplayOrder = (a, b) => {
        if (a.displayOrder === null || a.displayOrder === undefined) return 1;
        if (b.displayOrder === null || b.displayOrder === undefined) return -1;
        return a.displayOrder - b.displayOrder;
    };

    const sortedCategories = categories.sort(sortByDisplayOrder);
    const sortedSubcategories = subcategories.sort(sortByDisplayOrder);

    const classicCategoriesList = [
        "Metal & Enamel Dice",
        "Sharp Edge Dice",
        "Resin Dice",
        "Acrylic Dice"
    ]

    const getSubcategories = (categoryId) => {
        return sortedSubcategories.filter(subcategory => subcategory.parentCategoryId === categoryId);
    };

    const toggleCategory = (categoryId) => {
        setExpandedCategories(prevState =>
            prevState.includes(categoryId)
                ? prevState.filter(id => id !== categoryId)
                : [categoryId]
        );
    };

    const renderCategoryButton = (category, subcategories, isExpanded) => (
        <Button
            variant="flat"
            style={{ 'backgroundColor': category.hexColor, 'paddingLeft': '1.042vw' }}
            href={nonProductPage ? `/BulkOrder#${category.name}` : `#${category.name}`}
            onClick={() => {
                if (!expandedCategories.includes(category.ID)) {
                    if (subcategories.filter(subcategory => subcategory.visible && subcategory.hasButton).length > 0) {
                        toggleCategory(category.ID);
                    } else {
                        setExpandedCategories([]);
                    }
                }
            }}>
            {category.shortLabel}
            {checkMainOrSubCategoryNewProducts(category, subcategories) ? createNewBadge() : null}
            {subcategories.filter(subcategory => subcategory.visible && subcategory.hasButton).length > 0 ? (
                <Button
                    variant="flat"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        toggleCategory(category.ID);
                    }}
                    style={{
                        backgroundColor: category.hexColor,
                        marginLeft: checkMainOrSubCategoryNewProducts(category, subcategories) ? '0' : 'auto',
                        paddingLeft: '7px',
                        width: '25px',
                        textAlign: 'center',
                        scrollbarWidth: 'none',
                        marginBottom: '0',
                    }}>
                    {isExpanded ? '-' : '+'}
                </Button>
            ) : (
                <div style={{ width: '25px' }}></div>
            )}
        </Button>
    );

    const renderSubcategoryButtons = (subcategories, category) => (
        <div className="subcategories">
            {subcategories.filter(subcategory => subcategory.visible && subcategory.hasButton).map(subcategory => (
                <Button
                    key={subcategory.ID}
                    variant="flat"
                    style={{ 'backgroundColor': lightenColor(category.hexColor, 30) }}
                    href={nonProductPage ? `/BulkOrder#${subcategory.name}` : `#${subcategory.name}`}>
                    {subcategory.shortLabel}
                </Button>
            ))}
            {classicCategoriesList.includes(category.name) && (
                <Button
                    key={category.ID}
                    variant="flat"
                    style={{ 'backgroundColor': lightenColor(category.hexColor, 30) }}
                    href={nonProductPage ? `/BulkOrder#${category.name}-Classic` : `#${category.name}-Classic`}>
                    {category.shortLabel + " Classic"}
                </Button>
            )}
        </div>
    );

    const renderCategory = (category) => {
        const subcategories = getSubcategories(category.ID);
        const isExpanded = expandedCategories.includes(category.ID);
        return (
            <div key={category.ID} className="category-container">
                {renderCategoryButton(category, subcategories, isExpanded)}
                {isExpanded && renderSubcategoryButtons(subcategories, category)}
            </div>
        );
    };

    return (
        <div className="sideNav sticky" style={{
            maxHeight: '100vh',
            overflowY: 'auto',
            scrollbarWidth: 'none',
        }}>
            {sortedCategories
                .filter(category => category.visible && category.hasButton)
                .map(renderCategory)}
        </div>
    );

    function createNewBadge() {
        return <text tabIndex={-1}
            style={{ marginLeft: 'auto' }}
            className={"badge-new"}>New</text>;
    }
}