import React, { useEffect, useReducer, useContext, useState, Fragment } from "react";
import axios from "axios";
import { Store } from "../Store";
import ProductTable from "../components/ProductTableOLD";
import API from "../utils/API";
import { Button } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./bulkOrderScreenStyle.css"
import { lightenColor } from "../utils/ColorUtils";
import Category from "../models/Category";
import Product from "../models/Product";
import { mapDataToCategories, mapDataToProducts } from "../utils/DataUtils";
import { getDateXMonthsAgo } from "../utils/DateUtils";

const api_url = API();
export default function BulkOrderScreen() {
	const [categories, setCategories] = useState([]);
	const [subcategories, setSubcategories] = useState([]);
	const [search, setSearch] = useState("");
	const [isAscending, setIsAscending] = useState(true);
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { cart } = state;
	function scrollTop() {
		document.body.scrollTo({ top: 0, behavior: "smooth" });
	}

	function addToCartHandlerCategory(event, categoryId, isClassic = false) {
		const isMainCategory = categories.some(category => category.ID === categoryId && !category.isSubcategory);
		let bulkAmount = Math.round(event.target.value);

		let applicableProducts = [];
		if (categoryId === "new-arrivals") {
			applicableProducts = newArrivals;
		} else {
			applicableProducts = products.filter(product =>
				product.category_id === categoryId ||
				(isMainCategory && !isClassic && subcategories.some(sub => sub.parentCategoryId === categoryId && sub.ID === product.category_id))
			);
		}

		if (bulkAmount >= 0) {
			applicableProducts.forEach(product => {
				let temp = document.getElementById(product.product_id);
				if (temp) {
					temp.value = bulkAmount;
				}
				const existItem = cart.cartItems.find((x) => x.product_id === product.product_id);
				let quantity = bulkAmount;

				if (existItem) {
					ctxDispatch({
						type: bulkAmount > 0 ? "CART_ADD_ITEM" : "CART_REMOVE_ITEM",
						payload: { ...product, quantity },
					});
				} else if (bulkAmount > 0) {
					ctxDispatch({
						type: "CART_ADD_ITEM",
						payload: { ...product, quantity },
					});
				}
			});
		} else {
			alert("Please use a positive number!");
		}
	}

	function ClearCart() {
		const inputs = document.querySelectorAll("input");
		inputs.forEach((input) => {
			input.value = "";
		});
		ctxDispatch({ type: "CART_CLEAR" });
		localStorage.removeItem("cartItems");
	}

	const reducer = (state, action) => {
		switch (action.type) {
			case "FETCH_REQUEST":
				return { ...state, loading: true };
			case "FETCH_SUCCESS":
				return { ...state, products: action.payload, loading: false };
			case "FETCH_FAIL":
				return { ...state, loading: false, error: action.payload };
			default:
				return state;
		}
	};
	const [{ loading, error, products }, dispatch] = useReducer(reducer, {
		products: [],
		loading: true,
		error: "",
	});

	useEffect(() => {
		const fetchData = async () => {
			const { data: categoryData } = await axios.get(`${api_url}/api/db/categories`);
			const categories = mapDataToCategories(categoryData);
			const tempCategories = categories.filter(category => !category.isSubcategory);
			const tempSubcategories = categories.filter(category => category.isSubcategory);
			setCategories(tempCategories);
			setSubcategories(tempSubcategories);

			dispatch({ type: "FETCH_REQUEST" });
			try {
				const { data: productData } = await axios.get(`${api_url}/api/db/products`);
				const products = mapDataToProducts(productData);
				dispatch({ type: "FETCH_SUCCESS", payload: products });
			} catch (err) {
				dispatch({ type: "FETCH_FAIL", payload: err.message });
			}
		};
		fetchData();
	}, []);

	const newArrivals = products.filter(product => new Date(product.date_created) >= getDateXMonthsAgo(6));

	useEffect(() => {
		scrollTop();
	}, []);

	const reverseSort = (element) => {
		setIsAscending(!isAscending);
		setTimeout(() => {
			const targetElement = document.getElementById(element.name);
			if (targetElement) {
				targetElement.scrollIntoView();
			}
		}, 1);
	}

	const sortByDisplayOrder = (a, b) => {
		if (a.displayOrder === null || a.displayOrder === undefined) return 1;
		if (b.displayOrder === null || b.displayOrder === undefined) return -1;
		return a.displayOrder - b.displayOrder;
	};

	const sortedCategories = categories.sort(sortByDisplayOrder);
	const sortedSubcategories = subcategories.sort(sortByDisplayOrder);

	return (
		<div className="bulk-order-screen">
			<Helmet>
				<title>Dice Habit | Order Page</title>
			</Helmet>
			{orderingControls()}
			<div className="table-container">
				<table>
					<tbody>
						{/* New Arrivals Category */}
						{newArrivals.filter(product =>
							product.sku.toLowerCase().includes(search.toLowerCase()) ||
							product.item_name.toLowerCase().includes(search.toLowerCase())
						).length > 0 && search.length <= 0 && (
								<Fragment key="new-arrivals">
									<tr>
										<td
											id="new-arrivals"
											colSpan={9}
											className={'subTable-Category-scrollPadding'}
										>
											<th
												className="subTable-category-title"
												style={{
													backgroundColor: "#EE6311", // Gold color for new arrivals
													color: "white"
												}}>
												<div className="subTable-category-title-name">New Arrivals</div>
												<div className="lightning-bulkOrder-container">
													<div className="lightning-bulkOrder">I would like to order
														<br></br>
														{
															<input
																id="new-arrivals"
																name="BulkNewArrivals"
																type="number"
																pattern=" 0+\.[0-9]*[1-9][0-9]*$"
																step="1"
																min={0}
																placeholder="qty"
																className=""
																disabled={search.length > 0}
																onChange={(event) => {
																	const value = Math.max(0, event.target.value);
																	event.target.value = value;
																	addToCartHandlerCategory(event, "new-arrivals");
																}}>
															</input>
														}
														<br></br>
														of every set in New Arrivals</div>
												</div>
											</th>
										</td>
									</tr>
									<tr className="table-legend" id="new-arrivals-legend" style={{ backgroundColor: lightenColor("#EE6311", 30) }}>
										<th>#</th>
										<th></th>
										<th onClick={() => reverseSort({ name: "new-arrivals" })}>SKU { }
											{isAscending ? <i className="fa fa-arrow-up"></i> : <i className="fa fa-arrow-down"></i>}
										</th>
										<th>Product Name</th>
										<th>Image</th>
										<th>Retail</th>
										<th>Unit Cost</th>
										<th>Quantity</th>
										<th>Line Total</th>
									</tr>
									{renderProductTable(newArrivals, "new-arrivals", search, isAscending)}
								</Fragment>
							)}
						{sortedCategories
							.filter(category => category.visible)
							.filter(category => {
								const filteredProducts = products.filter(product =>
									product &&
									(product.category_id === category.ID ||
										subcategories.some(subcategory => subcategory.parentCategoryId === category.ID && subcategory.ID === product.category_id)) &&
									(product.sku.toLowerCase().includes(search.toLowerCase()) ||
										product.item_name.toLowerCase().includes(search.toLowerCase()))
								);
								return filteredProducts.length > 0;
							})
							.map((category) => (
								<Fragment key={category.ID}>
									<tr>
										<td id={`${category.name}`} colSpan={9}
											className={'subTable-Category-scrollPadding'}>
											<th className="subTable-category-title" style={{
												backgroundColor: category.hexColor,
												color: "white"
											}}>
												<div className="subTable-category-title-name">{category.name}</div>
												{categoryQuantityPriceBreakBubble(category.name)}
												<div className="lightning-bulkOrder-container">
													<div className="lightning-bulkOrder">
														I would like to order
														<br />
														<input
															id={category.ID}
															name={"Bulk" + category.ID}
															type="number"
															step="1"
															disabled={search.length > 0}
															min={0}
															placeholder="qty"
															onChange={(event) => {
																const value = Math.max(0, event.target.value);
																event.target.value = value;
																addToCartHandlerCategory(event, category.ID);
															}}
														/>
														<br />
														of every set in this Category
													</div>
												</div>
											</th>
										</td>
									</tr>
									{sortedSubcategories.filter(subcategory => subcategory.visible)
										.filter(subcategory => subcategory.parentCategoryId === category.ID)
										.filter(subcategory => {
											const filteredProducts = products.filter(product =>
												product &&
												product.category_id === subcategory.ID &&
												(product.sku.toLowerCase().includes(search.toLowerCase()) ||
													product.item_name.toLowerCase().includes(search.toLowerCase()))
											);
											return filteredProducts.length > 0;
										})
										.map(subcategory => (
											<Fragment key={subcategory.ID}>
												<tr>
													<td
														id={`${subcategory.name}`}
														colSpan={9}
														className={'subTable-Category-scrollPadding'}
													>
														<th
															className="subTable-subCategory-title"
															style={{
																backgroundColor: lightenColor(category.hexColor, 30) || subcategory.hexColor, //Default to grey if no color is found
																color: "white",
															}}>
															<div
																className="subTable-subCategory-title-name">{subcategory.name}</div>
															{categoryQuantityPriceBreakBubble(subcategory.name)}
															<div className="lightning-bulkOrder-container">
																<div className="lightning-bulkOrder">I would like to order
																	{
																		<input
																			id={subcategory.ID}
																			name={"Bulk" + subcategory.ID}
																			type="number"
																			pattern=" 0+\.[0-9]*[1-9][0-9]*$"
																			step="1"
																			disabled={search.length > 0}
																			min={0}
																			placeholder="qty"
																			className=""
																			style={{ marginInline: 5 }}
																			onChange={(event) => {
																				const value = Math.max(0, event.target.value);
																				event.target.value = value;
																				addToCartHandlerCategory(event, subcategory.ID);
																			}}>
																		</input>
																	}
																	of each set below
																</div>
															</div>
														</th>
													</td>
												</tr>
												<tr className="table-legend" id={subcategory + "1"}
													style={{ backgroundColor: lightenColor(category.hexColor, 30) }}>
													<th>#</th>
													<th></th>
													<th onClick={() => reverseSort(subcategory)}>SKU { }
														{isAscending ? <i className="fa fa-arrow-up"></i> :
															<i className="fa fa-arrow-down"></i>}
													</th>
													<th>Product Name</th>
													<th>Image</th>
													<th>Retail</th>
													<th>Unit Cost</th>
													<th>Quantity</th>
													<th>Line Total</th>
												</tr>
												{renderProductTable(products, subcategory.ID, search, isAscending)}
											</Fragment>
										))}
									{products.filter(product =>
										product.category_id === category.ID &&
										(product.sku.toLowerCase().includes(search.toLowerCase()) ||
											product.item_name.toLowerCase().includes(search.toLowerCase()))
									).length > 0 && (
											<Fragment>
												{subcategories.some(subcategory => subcategory.parentCategoryId === category.ID) && (<tr>
													<td id={`${category.name}-Classic`} colSpan={9}
														className={'subTable-Category-scrollPadding'}>
														<th className="subTable-subCategory-title" style={{
															backgroundColor: lightenColor(category.hexColor, 30),
															color: "white"
														}}>
															<div className="subTable-subCategory-title-name">{category.name} Classic</div>
															{categoryQuantityPriceBreakBubble(category.name)}
															<div className="lightning-bulkOrder-container">
																<div className="lightning-bulkOrder">
																	I would like to order
																	<input
																		id={category.ID}
																		name={"Bulk" + category.ID}
																		type="number"
																		step="1"
																		disabled={search.length > 0}
																		min={0}
																		placeholder="qty"
																		style={{ marginInline: 5 }}
																		onChange={(event) => {
																			const value = Math.max(0, event.target.value);
																			event.target.value = value;
																			addToCartHandlerCategory(event, category.ID, true);
																		}}
																	/>
																	of every set below
																</div>
															</div>
														</th>
													</td>
												</tr>)}
												<tr className="table-legend" id={category + "1"}
													style={{ backgroundColor: lightenColor(category.hexColor, 30) }}>
													<th>#</th>
													<th></th>
													<th onClick={() => reverseSort(category)}>SKU { }
														{isAscending ? <i className="fa fa-arrow-up"></i> :
															<i className="fa fa-arrow-down"></i>}
													</th>
													<th>Product Name</th>
													<th>Image</th>
													<th>Retail</th>
													<th>Unit Cost</th>
													<th>Quantity</th>
													<th>Line Total</th>
												</tr>
												{renderProductTable(products, category.ID, search, isAscending)}
											</Fragment>
										)}</Fragment>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);

	function orderingControls() {
		return <div className="stickyControls">
			<div className="checkOut-buttons-BulkOrder">
				<input
					type="search"
					id="search-input"
					placeholder="Search SKU or Name"
					onChange={(e) => setSearch(e.target.value)}
				/>
				<div>
					{categoryDollarPriceBreakBubble(cart)}
					<div className="parentContainer">
						<Button
							variant="flat"
							onClick={ClearCart}
							style={{ backgroundColor: "#222222" }}>
							Clear All
						</Button>
						<Link to="/cart">
							<Button
								variant="flat"
								onClick={scrollTop}
								style={{ backgroundColor: "#3EB489" }}>
								Checkout
							</Button></Link>
						<Button
							variant="flat"
							onClick={scrollTop}
							style={{ backgroundColor: "#0f2e93" }}
						>
							{getSubtotal(cart) <= 4000 ? (
								<Link to="/cart">SubTotal ${getSubtotal(cart)}</Link>
							) : (
								(4000 - cart.cartItems.reduce((a, c) => a + c.retail_price * 0.5 * c.quantity, 0)).toFixed(2) >= 0 ? (
									<div>
										<i className="fa fa-arrow-up"></i> <i className="fa fa-arrow-up"></i> See
										Above <i className="fa fa-arrow-up"></i> <i className="fa fa-arrow-up"></i>
									</div>
								) : <Link to="/cart">SubTotal ${getSubtotal(cart)}</Link>
							)}
						</Button>
					</div>
				</div>
			</div>
		</div>
	}
}
const categoryQuantityPriceBreakEnabled = false;
const categoryDollarPriceBreakEnabled = true;
let discountApplied = false;

function renderProductTable(products, category_id, search, isAscending = true) {
	// Apply discounts based on the discountApplied flag
	products.forEach((product) => {
		if (!discountApplied) {
			product.discount = 0.6;
		} else if (!getDiscountExclusions().includes(product.sku)) {
			product.discount = 0.5;
		}
	});

	// Filter and sort products, then map them to the ProductTable component
	return products
		.filter((product) => product.category_id === category_id || category_id === "new-arrivals")
		.filter((product) => {
			return product.sku.toLowerCase().includes(search.toLowerCase()) || product.item_name.toLowerCase().includes(search.toLowerCase());
		})
		.sort((a, b) => isAscending ? b.sku.localeCompare(a.sku) : a.sku.localeCompare(b.sku))
		.map((product) => (
			<ProductTable key={product.product_id} products={product}></ProductTable>
		));
}

function categoryDollarPriceBreakBubble(cart) {
	if (categoryDollarPriceBreakEnabled) {
		const dollarTarget = 4000;
		setDiscount(cart, dollarTarget);
		return (
			<div className="categoryDollarPriceBreakContainer">
				<p className="categoryDollarPriceBreakDeal">50% OFF Deal</p>
				<div className="categoryDollarPriceBreakLabel">
					<div>
						{cart.cartItems.reduce((a, c) => a + c.retail_price * .5 * c.quantity, 0) === 0 ? <span>Buy $<b>{dollarTarget}</b> to get <b>50% OFF MSRP</b></span> :
							cart.cartItems.reduce((a, c) => a + c.retail_price * .5 * c.quantity, 0) < dollarTarget ? <span>Add $<b>{remainderOfDollarTarget(dollarTarget)}</b> to get <b>50% OFF MSRP</b></span> :
								<span><text style={{ color: "#FFD700" }}>Yay! </text>You'll get <b>50% OFF MSRP</b> for your Order!</span>
						}
					</div>
					<text className="categoryDollarPriceBreakDealExclustion">*excluding a few 40% off only items*</text>
				</div>
			</div>);
	} else {
		return
	}

	function remainderOfDollarTarget(dollarTarget) {
		return ((dollarTarget - cart.cartItems.reduce((a, c) => a + c.retail_price * .5 * c.quantity, 0)).toFixed(2)).toString();
	}
}

function categoryQuantityPriceBreakBubble(categories) {
	if (categoryQuantityPriceBreakEnabled) {
		return <p className="Category-sales">Buy
			{categories === "Metal & Enamel Dice Set"
				|| categories === "(D20) Metal & Enamel Dice"
				|| categories === "Gemstone Dice Set"
				|| categories === "Glass Dice Set"
				|| categories === "(D6) Metal & Enamel Dice Set" ? " 4 " :
				" 6 "}
			of every set in this category for 50% off
			{categories === "Metal & Enamel Dice Set" ? " (excludes DAD552, DAD553, & Camouflage)" :
				categories === "Glass Dice Set" ? " (excludes DAD841 & DAD842)" :
					categories === "Resin Polyhedral Dice Set" ? " (excludes DAD065 & DAD066)" :
						""}
		</p>;
	} else {
		return
	}
}
function setDiscount(cart, dollarTarget) {
	let discountExclusions = getDiscountExclusions();
	if (categoryDollarPriceBreakEnabled) {
		if (cart.cartItems.reduce((a, c) => a + c.retail_price * .5 * c.quantity, 0) >= dollarTarget) {
			discountApplied = true;
			applyDiscountToCartItems(0.5);
		} else if (cart.cartItems.reduce((a, c) => a + c.retail_price * .5 * c.quantity, 0) <= dollarTarget && discountApplied) {
			discountApplied = false;
			applyDiscountToCartItems(0.6)
		}
	}

	function applyDiscountToCartItems(discountAmount) {
		cart.cartItems.forEach((item) => {
			if (!discountExclusions.includes(item.sku)) {
				item.discount = discountAmount;
			}
		});
	}
}

function getDiscountExclusions() {
	return ["DAD677", "DAD676", "DAD675", "DAD553", "DAD552", "DAD842", "DAD841", "DAD065", "DAD066", "DAD359", "DAD360"];
}

function getSubtotal(cart) {
	let subtotal = 0;
	cart.cartItems.forEach((item) => {
		subtotal += item.retail_price * item.quantity * item.discount;
	});
	return subtotal.toFixed(2);
}