/**
 * Represents a Category.
 */
class Category {
  /**
   * Creates an instance of Category.
   */
  constructor() {
    /**
     * @property {number} id - Primary Key (INT)
     */
    this.ID = 0;

    /**
     * @property {string} name - Category Name (VARCHAR)
     */
    this.name = "Temp Name";

    /**
     * @property {string} shortLabel - Short Label (VARCHAR)
     */
    this.shortLabel = "Label";

    /**
     * @property {string} hexColor - Hexadecimal Color Code (VARCHAR)
     */
    this.hexColor = "#CCCCCC";

    /**
     * @property {boolean} hasButton - Indicates if category has a button (TINYINT, boolean-like)
     */
    this.hasButton = 1;

    /**
     * @property {boolean} visible - Visibility flag (TINYINT, boolean-like)
     */
    this.visible = 1;

    /**
     * @property {boolean} subcategory - Indicates subcategory status (TINYINT, boolean-like)
     */
    this.isSubcategory = 1;

    /**
     * @property {number|null} displayOrder - Order of display (INT)
     */
    this.displayOrder = null;

    /**
     * @property {number|null} parentCategoryId - Parent Category ID (INT)
     */
    this.parentCategoryId = null;

    /**
     * @property {boolean} hasNewProducts - Flag for new products (TINYINT, boolean-like)
     */
    this.hasNewProducts = 0;
  }
}

export default Category;
