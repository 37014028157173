/**
 * Represents a Product.
 * @constructor
 * @property {number} productId - Primary Key (INT)
 * @property {string} sku - Stock Keeping Unit (VARCHAR)
 * @property {string} itemName - Product Name (VARCHAR)
 * @property {number} realTimeQty - Real-time Quantity (INT)
 * @property {string} upc - Universal Product Code (VARCHAR)
 * @property {string} labelDisplay - Display Label (VARCHAR)
 * @property {string} labelPrint - Print Label (VARCHAR)
 * @property {string} category - Product Category (VARCHAR)
 * @property {number|null} cmr - Custom Metric Rate (FLOAT)
 * @property {number} unitCost - Unit Cost (FLOAT)
 * @property {number} baseCost - Base Cost (FLOAT)
 * @property {number} packagingCost - Packaging Cost (FLOAT)
 * @property {number} freightCost - Freight Cost (FLOAT)
 * @property {number} retailPrice - Retail Price (FLOAT)
 * @property {number} discount - Discount Rate (FLOAT)
 * @property {number|null} itemWeightLbs - Item Weight in lbs (FLOAT)
 * @property {number|null} itemVolumeIn3 - Item Volume in cubic inches (FLOAT)
 * @property {number} ctnQty - Quantity per Carton (INT)
 * @property {number|null} ctnWeightKg - Weight per Carton in kg (FLOAT)
 * @property {string} imagePath - Path to Product Image (VARCHAR)
 * @property {boolean} websiteViewable - Whether the product is viewable on the website (TINYINT)
 * @property {boolean} availabilityStatus - Availability Status (TINYINT)
 * @property {string|null} dateCreated - Creation Date (DATE)
 * @property {number} adjustmentQty - Adjustment Quantity (INT)
 * @property {number} categoryId - Category ID (INT)
 */
class Product {
  /**
   * Creates an instance of Product.
   */
  constructor() {
    /**
     * @property {number} productId - Primary Key (INT)
     */
    this.product_id = 0;

    /**
     * @property {string} sku - Stock Keeping Unit (VARCHAR)
     */
    this.sku = "";

    /**
     * @property {string} itemName - Product Name (VARCHAR)
     */
    this.item_name = "";

    /**
     * @property {number} realTimeQty - Real-time Quantity (INT)
     */
    this.realTime_qty = 0;

    /**
     * @property {string} upc - Universal Product Code (VARCHAR)
     */
    this.upc = "";

    /**
     * @property {string} labelDisplay - Display Label (VARCHAR)
     */
    this.label_display = "";

    /**
     * @property {string} labelPrint - Print Label (VARCHAR)
     */
    this.label_print = "NA";

    /**
     * @property {string} category - Product Category (VARCHAR)
     */
    this.category = "";

    /**
     * @property {number|null} cmr - Custom Metric Rate (FLOAT)
     */
    this.cmr = null;

    /**
     * @property {number} unitCost - Unit Cost (FLOAT)
     */
    this.unit_cost = 0;

    /**
     * @property {number} baseCost - Base Cost (FLOAT)
     */
    this.base_cost = 0;

    /**
     * @property {number} packagingCost - Packaging Cost (FLOAT)
     */
    this.packaging_cost = 0;

    /**
     * @property {number} freightCost - Freight Cost (FLOAT)
     */
    this.freight_cost = 0;

    /**
     * @property {number} retailPrice - Retail Price (FLOAT)
     */
    this.retail_price = 0;

    /**
     * @property {number} discount - Discount Rate (FLOAT)
     */
    this.discount = 0.6;

    /**
     * @property {number|null} itemWeightLbs - Item Weight in lbs (FLOAT)
     */
    this.item_weight_lbs = null;

    /**
     * @property {number|null} itemVolumeIn3 - Item Volume in cubic inches (FLOAT)
     */
    this.item_volume_in3 = null;

    /**
     * @property {number} ctnQty - Quantity per Carton (INT)
     */
    this.ctn_qty = 0;

    /**
     * @property {number|null} ctnWeightKg - Weight per Carton in kg (FLOAT)
     */
    this.ctn_weight_kg = null;

    /**
     * @property {string} imagePath - Path to Product Image (VARCHAR)
     */
    this.image_path = "";

    /**
     * @property {boolean} websiteViewable - Whether the product is viewable on the website (TINYINT)
     */
    this.website_viewable = false;

    /**
     * @property {boolean} availabilityStatus - Availability Status (TINYINT)
     */
    this.availability_status = true;

    /**
     * @property {string|null} dateCreated - Creation Date (DATE)
     */
    this.date_created = null;

    /**
     * @property {number} adjustmentQty - Adjustment Quantity (INT)
     */
    this.adjustment_qty = 0;

    /**
     * @property {number} FK_products_users - Foreign Key to Products Users (INT)
     */
    this.FK_products_users = 0;

    /**
     * @property {number} categoryId - Category ID (INT)
     */
    this.category_id = null;
  }
}

export default Product;