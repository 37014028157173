import React from "react";
import { Helmet } from "react-helmet";

function ProductPackagingScreen() {
    return (
        <div className="container-fluid">
            {/* <div className="container"> */}
            <div>
                <Helmet>
                    <title>
                        Dice Habit | Packaging
                    </title>
                </Helmet>
            </div>
            {/* Start Barcode and Labelling */}
            <div className="Product-Group">
                <h4 className="Category-Header row center anchor" style={{ 'background-color': '#656263' }}>
                    Barcode and Labeling
                </h4>
                <div className="row" style={{ 'padding': '2%' }}>
                    <div className="barcode col-sm">
                        <img src="images/Barcode.svg" style={{ 'width': '18.341vw', 'height': "auto" }} alt="Barcode Sample"></img>
                    </div>
                    <div className="col-sm">
                        <span className="alt-expo">
                            <i>
                                All of our products come labeled with an easy to read SKU number and Barcode for easy integration with your Point of Sales system.
                            </i>
                        </span>
                    </div>
                </div>
            </div>
            {/* End Barcode and Labeling Jazz */}

            {/* Start Product Packaging */}
            <div className="Product-Group" style={{ 'paddingBottom': '2%' }}>
                <h4 className="Category-Header row center anchor" style={{ 'background-color': '#6bcb9c' }}>
                    Product Packaging
                </h4>
                <div className="grid">
                    <div className="grid-item" style={{ 'padding': '2%' }}>
                        <span className="alt-expo">
                            <i>
                                We understand just how important packaging and display is for your retail store, which is why all of our products come with a beautifully
                                designed, highly practical retail packaging
                            </i>
                        </span>
                    </div>
                    {/* Top Row */}
                    <div className="row center row">
                        <img src="images/DAD626b.jpg" className="card-img-top-Packaging" alt="Rectangle Dragon Box" style={{ 'width': '45vw' }}></img>
                        <span className="card-text" style={{ 'font-size': '1.3vw' }}>
                            <strong style={{ 'font-style': 'italic', 'font-weight': 'bolder' }}>Rectangle Dragon Box</strong> <br></br>
                            <i style={{ 'font-weight': 'lighter' }}>Sharp Edge Dice | Hollow Dice | Gemstone Dice</i>
                        </span>
                        <img src="images/DAD580b.jpg" className="card-img-top-Packaging" alt="Rectangle Dragon Box" style={{ 'width': '47vw' }}></img>
                        <span className="card-text" style={{ 'font-size': '1.3vw' }}>
                            <strong style={{ 'font-style': 'italic', 'font-weight': 'bolder' }}>Wooden Dice Tray</strong> <br></br>
                            <i style={{ 'font-weight': 'lighter' }}>d20 Metal and Enamel Dice Assortment</i>
                        </span>
                    </div>
                    {/* End top row */}

                    {/* Start Second Row */}
                    <div className="row center row">
                        <img src="images/DAD527b.jpg" className="card-img-top-Packaging" alt="Rectangle Dragon Box" style={{ 'width': '45vw' }}></img>
                        <span className="card-text" style={{ 'font-size': '1.3vw' }}>
                            <strong style={{ 'font-style': 'italic', 'font-weight': 'bolder' }}>Window Tin Box</strong> <br></br>
                            <i style={{ 'font-weight': 'lighter' }}>Dragon Scale Dice | Metal & Enamel Dice</i>
                        </span>
                        <img src="images/DAD316c.jpg" className="card-img-top-Packaging" alt="Rectangle Dragon Box" style={{ 'width': '50vw' }}></img>
                        <span className="card-text" style={{ 'font-size': '1.3vw' }}>
                            <strong style={{ 'font-style': 'italic', 'font-weight': 'bolder' }}>Window Tin Cylinder</strong> <br></br>
                            <i style={{ 'font-weight': 'lighter' }}>Metal d100 Dice</i>
                        </span>
                    </div>
                    {/* End Second Row */}
                </div>
            </div>
        </div>
    );
}

export default ProductPackagingScreen;