/**
 * @fileoverview This file contains the implementation of a React context and reducer for managing a shopping cart.
 * The cart state is persisted in localStorage and includes functionality to add, remove, and clear items from the cart.
 */

import React from "react";
import { createContext, useReducer } from "react";
import PropTypes from "prop-types";

/**
 * Creates a context for the store.
 * @type {React.Context}
 */
export const Store = createContext();

/**
 * Initial state of the store.
 * @type {Object}
 * @property {Object} cart - The cart object.
 * @property {Array} cart.cartItems - The list of items in the cart.
 */
const initialState = {
  cart: {
    cartItems: localStorage.getItem('cartItems') 
    ? JSON.parse(localStorage.getItem('cartItems'))
    : [],
  },
};

/**
 * Reducer function to manage the state of the cart.
 * @param {Object} state - The current state of the store.
 * @param {Object} action - The action to be performed.
 * @param {string} action.type - The type of action.
 * @param {Object} action.payload - The payload of the action.
 * @returns {Object} The new state of the store.
 */
function reducer(state, action) {
  switch (action.type) {
    case "CART_ADD_ITEM": {
      // Add item to cart
      const newItem = action.payload;
      const existItem = state.cart.cartItems.find(
        (item) => item.product_id === newItem.product_id
      );
      const cartItems = existItem
        ? state.cart.cartItems.map((item) =>
            item.product_id === existItem.product_id ? newItem : item
          )
        : [...state.cart.cartItems, newItem];
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      if (localStorage.getItem('cartItems').length > 1) {
        var cartItemsList = JSON.parse(localStorage.getItem('cartItems'));
        cartItemsList.sort(function (a, b) {
          return a.product_id - b.product_id;
        });
        localStorage.setItem('cartItems', JSON.stringify(cartItemsList));
      }
      return { ...state, cart: { ...state.cart, cartItems } };
    }
    case "CART_REMOVE_ITEM": {
      // Remove item from cart
      const cartItems = state.cart.cartItems.filter(
        (item) => item.product_id !== action.payload.product_id
      );
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      return { ...state, cart: { ...state.cart, cartItems } };
    }
    case 'CART_CLEAR':
      // Clear the cart
      return { ...state, cart: { ...state.cart, cartItems: [] } };
    default:
      return state;
  }
}

/**
 * StoreProvider component to provide the store context to its children.
 * @param {Object} props - The props of the component.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {JSX.Element} The StoreProvider component.
 */
export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children} </Store.Provider>;
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
