
import Category from "../models/Category";
import Product from "../models/Product";

export const mapDataToCategories = (data) => {
    return data.map((categoryData) => {
        const category = new Category();
        Object.assign(category, categoryData);
        return category;
    });
};

export const mapDataToProducts = (data) => {
    return data.map((productData) => {
        const product = new Product();
        Object.assign(product, productData);
        return product;
    });
};